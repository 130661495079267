import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';

const CompletePurchase = ({language}) => {
    const [content, setContent] = useState('')

    useEffect(() => {
        const loadContent = async () => {
          const response = await import(`../data/shop/${language}.json`);
          setContent(response);
        };
        loadContent();
    }, [language]);

    if (!content) return <Typography>Loading...</Typography>;

    return (
        <Container>
          <SetHeaderData content={{ title: 'Complete Purchase' }} />
          Thank you, [customer name]! We got your order and it’s quite gorgeous. We’ll send you an email when it ships.
        </Container>
    )
}

export default CompletePurchase;