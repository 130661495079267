import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'; // Import react-markdown
import { Container, Typography, Box, useTheme, Button, TextField } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import axios from 'axios';

const VirtualAssistant = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null); // Load static content
  const [chatHistory, setChatHistory] = useState([]); // Store conversation
  const [userInput, setUserInput] = useState(""); // User's current input
  const [threadId, setThreadId] = useState(null); // Track the thread ID
  const [isLoading, setIsLoading] = useState(false);
  const [showPredefinedQuestions, setShowPredefinedQuestions] = useState(true);

  // Load static content (e.g., massage info in JSON)
  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/virtual_assistant/${language}.json`);
      setContent(response.default);
    };
    loadContent();
  }, [language]);

  // Utility function to clean up assistant's response
  // Utility function to clean up assistant's response
  const cleanResponse = (response) => {
    return response
      .replace(/\[object Object\]/g, '') // Remove "[object Object]"
      .replace(/【\d+:\d+†source】/g, '') // Remove tags like " "
  };

  // Handle user input submission
  const handleSend = async (message = userInput) => {
    setShowPredefinedQuestions(false)
    if (!message.trim()) return;
  
    // Add user's message to chat history
    const updatedHistory = [...chatHistory, { role: "user", content: message }];
    setChatHistory(updatedHistory);
  
    // Add a placeholder "thinking" message
    setChatHistory((prev) => [
      ...prev,
      { role: "assistant", content: content.thinking },
    ]);
  
    setIsLoading(true);
  
    try {
      // Construct the request payload
      const payload = threadId
        ? { threadId, messages: [{ role: "user", content: message }] }
        : { messages: [{ role: "user", content: message }] };
  
      // Call the backend
      const response = await axios.post('https://rachinee.ch/Proxy.php', payload);
  
      // Clean and process the assistant's response
      const assistantResponse = cleanResponse(response.data.assistant);
  
      // Save threadId from the response, if it's the first request
      if (!threadId && response.data.threadId) {
        setThreadId(response.data.threadId);
      }
  
      // Replace the "thinking" message with the actual assistant's response
      setChatHistory((prev) => {
        const updatedChat = [...prev];
        updatedChat[updatedChat.length - 1] = { role: "assistant", content: assistantResponse };
        return updatedChat;
      });
    } catch (error) {
      console.error("Error with OpenAI API:", error);
      setChatHistory((prev) => {
        const updatedChat = [...prev];
        updatedChat[updatedChat.length - 1] = {
          role: "assistant",
          content: "Sorry, something went wrong. Please try again.",
        };
        return updatedChat;
      });
    } finally {
      setIsLoading(false);
      setUserInput(""); // Clear input field
    }
  };

  const components = {
    a: ({ href, children }) => (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: theme.palette.text.link, // Use theme link color
          textDecoration: 'none',
        }}
        onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
        onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
      >
        {children}
      </a>
    ),
  };

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <SetHeaderData content={content} />
      <Box
        sx={{
          mt: 4,
          p: 2,
          borderRadius: 1,
          boxShadow: 3,
          backgroundColor: theme.palette.background.paper
        }}
      >
        <Typography variant="h5">{content.title}</Typography>
        <Typography variant="body">{content.content}</Typography>

        {showPredefinedQuestions && (
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            {content.questions.map((question, index) => (
              <Button
                key={index}
                variant="outlined"
                color="secondary"
                onClick={() => handleSend(question)}
                disabled={isLoading}
              >{question}
              </Button>
            ))}
          </Box>
        )}

        <Box sx={{ mt: 2, maxHeight: 600, overflowY: 'auto' }}>
          {chatHistory.map((message, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                my: 1,
                color: message.content === content.thinking ? theme.palette.text.secondary : theme.palette.text.primary,
                backgroundColor:
                  message.role === 'user'
                    ? theme.palette.background.chatInput
                    : theme.palette.background.chatResponse,
                borderRadius: '8px',
                border: `1px solid ${theme.palette.divider}`,
                alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
                maxWidth: '80%',
                fontStyle: message.content === content.thinking ? "italic" : "normal",
                ...(message.content === content.thinking ? theme.components.blinkingText : {}), // Apply blinking style
              }}
            >
              {message.role === 'assistant' ? (
                <ReactMarkdown components={components}>{message.content}</ReactMarkdown>
              ) : (
                <Typography>{message.content}</Typography>
              )}
            </Box>
          ))}
        </Box>

        {!isLoading && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <TextField
              fullWidth
              placeholder={content.type_message}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              disabled={isLoading}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSend(userInput)}
              disabled={isLoading}
              sx={{ ml: 1 }}
            >
              {content.send}
            </Button>
          </Box>
        )}

        </Box>
    </Container>
  );
};

export default VirtualAssistant;
