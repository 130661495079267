import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SetHeaderData from '../components/SetHeaderData';
import { v4 as uuidv4 } from 'uuid';

const Checkout = ({language}) => {
  const location = useLocation();
  const { shoppingCart } = location.state || {};
  const [content, setContent] = useState(null);
  const [amount] = useState(shoppingCart.reduce((total, article) => total + article.price, 0).toFixed(2))
  const [articles] = useState(shoppingCart.reduce((articles_string, article) => articles_string + "," + article.id))
  const SUMUP_API_KEY = process.env.REACT_APP_SUMUP_API_KEY;
  const [customer, setCustomer] = useState({
    name: '',
    middleName: '',
    familyName: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phoneNumber: '',
    eMail: '',
  });
  
  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/shop/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  const handleCustomerChange = (field, value) => {
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [field]: value,
    }));
  };

  const handleSubmit = async (amount, customer, articles) => {

    const checkout_reference = uuidv4();   
    
    const purchase_status_params = "?checkout_reference=" + checkout_reference + "&name=" + customer.name + 
      "&familyName=" + customer.familyName + "&address=" + customer.address + 
      "&zip=" + customer.zip + "&city=" + customer.zip + "&country=" + customer.country + 
      "&phoneNumber=" + customer.phoneNumber + "&eMail=" + customer.eMail + "&amount=" + amount +
      "&articles=" + articles;
    
    const return_params = "?name=" + customer.name + "&familyName=" + customer.familyName

    const purchase_description = "To track your order, please note: " + checkout_reference;

    try {

      const body =  JSON.stringify({
        checkout_reference,
        customer,
        articles: shoppingCart,
        amount
      })

      const trackOrderResponse = await fetch('https://rachinee.ch/TrackOrders.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          checkout_reference,
          customer,
          articles: shoppingCart,
          amount
        }),
      });

      const rawResponseText = await trackOrderResponse.text();
      const trackOrderResult = JSON.parse(rawResponseText);

      if (trackOrderResponse.ok) {
          console.log('Order saved successfully:', trackOrderResult);
      } else {
          console.error('Failed to save order:', trackOrderResult);
          alert('Failed to save your order. Please try again.');
          return;
      }

      const create_checkout = await fetch('https://api.sumup.com/v0.1/checkouts', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${SUMUP_API_KEY}`,
        },
        body: JSON.stringify({
        amount: amount,
        currency: 'CHF',
        checkout_reference: checkout_reference,
        description: purchase_description,
        merchant_code: process.env.REACT_APP_SUMUP_MERCHANT_CODE,
        return_url: "https://rachinee.ch/CheckoutStatus.php" + purchase_status_params,
        redirect_url: "https://rachinee.ch/complete_purchase" + return_params,
        hosted_checkout_settings: { enabled: true }
        })
      });

      const data = await create_checkout.json();
      
      if (data.hosted_checkout_url) {
        window.location.href = data.hosted_checkout_url;
      } else {
        console.error('No checkout URL found:', data);
      }
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  if (!content) return <Typography>Loading...</Typography>;

  if (!shoppingCart || !shoppingCart.length) {
    return <Typography>No items in the cart. Please add items first.</Typography>;
  }

  return (
    <Container>
      <SetHeaderData content={{ title: 'Checkout' }} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
          Checkout
        </Typography>

        <Box>
          {shoppingCart.map((item, index) => (
            <Typography key={index} variant="body1" sx={{ my: 1 }}>
              {item.title} - {item.price} CHF
            </Typography>
          ))}
        </Box>

        <Typography variant="h6" sx={{ my: 2 }}>
          Total: {amount} CHF
        </Typography>

        <Box sx={{ my: 4 }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={customer.name}
            onChange={(e) => handleCustomerChange('name', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Middle Name"
            variant="outlined"
            fullWidth
            value={customer.middleName}
            onChange={(e) => handleCustomerChange('middleName', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Family Name"
            variant="outlined"
            fullWidth
            value={customer.familyName}
            onChange={(e) => handleCustomerChange('familyName', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            value={customer.address}
            onChange={(e) => handleCustomerChange('address', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="ZIP"
            variant="outlined"
            fullWidth
            value={customer.zip}
            onChange={(e) => handleCustomerChange('zip', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            value={customer.city}
            onChange={(e) => handleCustomerChange('city', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Country"
            variant="outlined"
            fullWidth
            value={customer.country}
            onChange={(e) => handleCustomerChange('country', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={customer.phoneNumber}
            onChange={(e) => handleCustomerChange('phoneNumber', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={customer.eMail}
            onChange={(e) => handleCustomerChange('eMail', e.target.value)}
            sx={{ mb: 2 }}
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(amount, customer, articles)}
          sx={{ mt: 2 }}
        >
          Confirm Order
        </Button>
      </Box>
    </Container>
  );
};

export default Checkout;
